import { SvgComponent } from "asset/page";
import cx from "classnames";
import { breakpointMap } from "data/breakpoint.data";
import { RoutePath } from "data/routeList";
import { Link } from "react-router-dom";
import { useMedia } from "react-use";
import "styles/component/Feature/FeatureDetail.sass";

interface FeatureDetailItemType {
  ItemLeadingComponent: SvgComponent;
  itemTitle: string;
  itemDescription: string;
}

export const FeatureDetail = ({
  LeadingComponent,
  BottomIllustrationComponent,
  title,
  description,
  detailItemList,
  isReverse = false,
  detailImgUrl,
  learnMoreUrl,
  className,
}: {
  LeadingComponent: SvgComponent;
  BottomIllustrationComponent?: SvgComponent;
  title: string;
  description: string;
  detailItemList: FeatureDetailItemType[];
  isReverse?: boolean;
  detailImgUrl: string;
  learnMoreUrl: RoutePath;
  className?: string;
}) => {
  const isLg = useMedia(breakpointMap.lg);

  const renderDetailItemsContainer = () => (
    <div className="content-items-container">
      {detailItemList.map((detailItem) => (
        <div
          key={detailItem.itemTitle + detailItem.itemDescription}
          className="content-item"
        >
          <div className="item-img-container">
            <detailItem.ItemLeadingComponent className="item-img" />
          </div>
          <div className="item-content">
            <p className="item-content-title">{detailItem.itemTitle}</p>
            <p className="item-content-description">
              {detailItem.itemDescription}
            </p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={cx("feature-detail", className)}>
      <div
        className={cx("feature-detail-inner", {
          "flex-col": !isLg,
          "flex-row": isLg && !isReverse,
          "flex-row-reverse": isLg && isReverse,
        })}
      >
        <div
          className="detail-content-container"
          data-aos={isReverse ? "fade-left" : "fade-right"}
          data-aos-delay="0"
          data-aos-duration="800"
        >
          <div className="detail-content">
            <div className="content-title">
              <LeadingComponent />
              <p className="content-title-text">{title}</p>
            </div>
            <p className="content-description">{description}</p>
            {renderDetailItemsContainer()}

            <Link to={learnMoreUrl} className="learn-more">
              Learn more
            </Link>
          </div>
        </div>
        <div
          className="detail-image-container"
          data-aos={isReverse ? "fade-right" : "fade-left"}
          data-aos-delay="100"
          data-aos-duration="800"
        >
          <img src={detailImgUrl} alt="Single Alert" />
        </div>
      </div>

      {BottomIllustrationComponent && (
        <BottomIllustrationComponent className="bottom-illustration-component" />
      )}
    </div>
  );
};
