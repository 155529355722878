import lottie, {
  AnimationConfigWithData,
  AnimationConfigWithPath,
  AnimationItem,
} from "lottie-web";
import { useEffect, useRef } from "react";

interface LottiePlayerProps {
  animationData: any;
  width?: number;
  height?: number;
  options?: Partial<AnimationConfigWithPath | AnimationConfigWithData>;
}
export const LottiePlayer = ({
  animationData,
  height,
  width,
  options,
}: LottiePlayerProps) => {
  const lottieController = useRef<AnimationItem>();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      if (lottieController.current) {
        lottieController.current.destroy();
      }

      const newController = lottie.loadAnimation({
        container: containerRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
        ...options,
      });

      lottieController.current = newController;
    }
  }, [containerRef, animationData, options]);

  return (
    <div
      ref={containerRef}
      style={{
        width: width || "100%",
        height: height || "100%",
        overflow: "hidden",
        margin: "0 auto",
        outline: "none",
      }}
    />
  );
};
