import cx from "classnames";
import { Icon } from "component";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import scrollIntoView from "scroll-into-view";
import "styles/component/Carousel.sass";

interface CarouselProps<DataType> {
  dataList: DataType[] | readonly DataType[];
  renderItem: (item: DataType, index: number) => JSX.Element;
  duration?: number;
  useContrastColor?: boolean;
}
export function Carousel<T>({
  dataList,
  renderItem,
  duration,
  useContrastColor,
}: CarouselProps<T>) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const eleList = useRef<HTMLElement[]>();
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      eleList.current = Array.from(container.children) as HTMLElement[];
    }
  }, []);

  const showItem = (index: number) => () => {
    if (eleList.current) {
      scrollIntoView(eleList.current[index], { time: duration || 200 });
      setSelectedIndex(index);
    }
  };

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (container) {
      const eleList = Array.from(container.children) as HTMLElement[];

      if (eleList.length > 0) {
        const observer = new IntersectionObserver(
          (entryList) => {
            const target = entryList[0].target;
            const targetIndex = eleList.findIndex((ele) => ele === target);
            if (targetIndex != null) {
              setSelectedIndex(targetIndex);
            }
          },
          { root: container, rootMargin: "0px", threshold: 0.7 },
        );

        eleList.forEach((ele) => {
          observer.observe(ele);
        });
      }
    }
  }, []);

  return (
    <div className={cx("carousel", { "carousel--contrast": useContrastColor })}>
      <div className="content-container" ref={containerRef}>
        {dataList.map((item, index) => (
          <Fragment key={`carousel-item---${index}`}>
            {renderItem(item, index)}
          </Fragment>
        ))}
      </div>

      <div className="navigation-container">
        {Array.from({ length: dataList.length }, (_, index) => (
          <button
            key={index}
            className={cx("navigation-dot", {
              [`navigation-dot--active`]: index === selectedIndex,
            })}
            onClick={showItem(index)}
          >
            <Icon icon={["fas", "circle"]} />
          </button>
        ))}
      </div>
    </div>
  );
}
