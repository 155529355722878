import Icon from "component/Icon";
import { stockwiseRegisterUrl } from "data/common";
import { PricingTitle } from "data/page/Pricing.data";
import React, { ReactNode } from "react";
import ReactTooltip from "react-tooltip";
import "styles/component/Pricing/PricingCard.sass";

export interface PricingCardProp {
  Illustration: React.FC<React.SVGProps<SVGSVGElement>>;
  content: ReactNode;
  title: PricingTitle;
  price: string;
  des: string;
  popular?: ReactNode;
}

const PricingCard = ({
  Illustration,
  content,
  title,
  price,
  des,
  popular,
}: PricingCardProp) => {
  return (
    <div className="pricing-card-wrapper">
      <div className="pricing-card">
        <div className="light-background"> </div>
        {popular}
        <div className="des">
          <Illustration className="illustration" />
          <h2>{title}</h2>

          <div className="price">
            <p>{price}</p>
            {title !== "Free" && (
              <>
                <p data-tip="Free for a Limited Time">
                  <Icon icon={["fas", "info-circle"]} />
                </p>
                <ReactTooltip />
              </>
            )}
          </div>

          <p>{des}</p>
          <a href={stockwiseRegisterUrl}>Sign up today</a>
        </div>
        {content}
      </div>
    </div>
  );
};

export default PricingCard;
