// import { createStore, createTypedHooks } from "easy-peasy";
import { createStore, createTypedHooks } from "easy-peasy";
import { blogStore, BlogStoreModel } from "stores/blog/blog.store";

export interface StoreModel {
  blogStore: BlogStoreModel;
}

const model: StoreModel = {
  blogStore,
};

const { useStoreState, useStoreActions } = createTypedHooks<StoreModel>();

export const store = createStore(model);
export { useStoreState, useStoreActions };
