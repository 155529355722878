import { Basic, Elite, Free, Premium } from "asset/page";
import Icon from "component/Icon";
import { ReactNode } from "react";

export type PricingTitle = "Free" | "Basic" | "Premium" | "Elite";
interface PricingItem {
  Illustration: React.FC<React.SVGProps<SVGSVGElement>>;
  content: ReactNode;
  title: PricingTitle;
  monthlyPrice: string;
  annualPrice: string;
  des: string;
  popular?: ReactNode;
}

export const pricingList: PricingItem[] = [
  {
    title: "Free",
    des: "Perfect for brand new traders and to learn how Stockwise works ",
    content: (
      <div className="content">
        <p>Journal Feature</p>
        <p>3 Basic Alerts</p>
        <p>1 Chained Alert</p>
        <p>Advertisement</p>
        <p>Push Notification for Alert</p>
        <p>Email Notification for Alert</p>
      </div>
    ),
    Illustration: Free,
    monthlyPrice: "Free",
    annualPrice: "Free",
  },
  {
    title: "Basic",
    des:
      "For traders who are more active in the market and are making weekly trades",
    content: (
      <div className="content">
        <p>All FREE tier features</p>
        <p>Unlimited Basic Alerts</p>
        <p>3 Chained Alerts</p>
        <p>No Ads</p>
      </div>
    ),
    Illustration: Basic,
    monthlyPrice: "Free",
    annualPrice: "$1 per month",
  },
  {
    title: "Premium",
    des:
      "Advanced features for advance traders who want to use complicated alerts and technical indicators ",
    content: (
      <div className="content">
        <p>All BASIC tier Features</p>
        <p>Unlimited Alerts</p>
        <p>SMS Notification for Alerts</p>
        <p>Smart Alerts (Under Development)</p>
        <p>Upload Broker CSV for Journals</p>
      </div>
    ),
    Illustration: Premium,
    monthlyPrice: "Free",
    annualPrice: "$48 per year",
    popular: (
      <div className="popular">
        <p>Most Popular </p>
        <Icon icon={["fas", "star"]} />
      </div>
    ),
  },

  {
    title: "Elite",
    des:
      "Everything Stockwise has to offer for traders that dedicated to taking their trading to the next level",
    content: (
      <div className="content">
        <p>All PREMIUM tier Feature</p>
        <p>Broker Integration</p>
        <p>Journal AI (Under Development)</p>
        <p>Watch List's Sparkline Chart</p>
      </div>
    ),
    Illustration: Elite,
    monthlyPrice: "Free",
    annualPrice: "$96 per year",
  },
];
