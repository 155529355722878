import { UserBlueDuotoneSVG } from "asset/page";
import { socialFeedLandingVideoUrl } from "asset/videos";
import { VideoPlayer } from "component/VideoPlayer";
import { breakpointMap } from "data/breakpoint.data";
import { useOnScreen } from "hooks/useOnScreen";
import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { useMedia } from "react-use";
import "styles/component/Feature/FeatureSocialFeedSection.sass";

export const FeatureSocialFeedSection = () => {
  const isMd = useMedia(breakpointMap.md);

  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoPlayerRef = useRef<ReactPlayer>(null);
  // Workaround because onReady is fired every single time seek is called
  const didSeek = useRef(false);
  const isOnScreen = useOnScreen(videoContainerRef);

  useEffect(() => {
    didSeek.current = false;
  }, [isMd]);

  useEffect(() => {
    const player = videoPlayerRef.current;
    if (player && isMd) {
      if (isOnScreen) {
        // Automatically restart video when it's on screen
        player.getInternalPlayer().play();
      }
    }
  }, [isOnScreen, isMd]);

  const handleVideoOnReady = (videoPlayer: ReactPlayer) => {
    if (!didSeek.current) {
      if (isMd) {
        videoPlayer.seekTo(0);
      } else {
        videoPlayer.seekTo(0.99, "fraction");
      }
    }
    didSeek.current = true;
  };

  return (
    <div className="feature-social-feed-section">
      <div className="inner-container">
        <div className="info-container">
          <div className="info">
            <div className="header">
              <h3 className="title">
                <UserBlueDuotoneSVG />
                <p>Social</p>
              </h3>

              <p className="sub-title">Don’t go at it alone</p>
            </div>

            <p className="description">
              Stay connected with other traders and build your own community.
            </p>
          </div>
        </div>

        <VideoPlayer
          url={socialFeedLandingVideoUrl}
          ref={videoPlayerRef}
          containerRef={videoContainerRef}
          onReady={handleVideoOnReady}
        />
      </div>
    </div>
  );
};
