import cx from "classnames";
import React, { CSSProperties, forwardRef, ReactElement } from "react";
import ReactPlayer from "react-player";
import { SourceProps } from "react-player/base";

/** Taken from React Player */
interface ReactPlayerProps {
  url?: string | string[] | SourceProps[] | MediaStream;
  playing?: boolean;
  loop?: boolean;
  controls?: boolean;
  volume?: number;
  muted?: boolean;
  playbackRate?: number;
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
  progressInterval?: number;
  playsinline?: boolean;
  playIcon?: ReactElement;
  previewTabIndex?: number;
  pip?: boolean;
  stopOnUnmount?: boolean;
  light?: boolean | string;
  fallback?: ReactElement;
  wrapper?: any;
  onReady?: (player: ReactPlayer) => void;
  onStart?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onBuffer?: () => void;
  onBufferEnd?: () => void;
  onEnded?: () => void;
  onClickPreview?: (event: any) => void;
  onEnablePIP?: () => void;
  onDisablePIP?: () => void;
  onError?: (
    error: any,
    data?: any,
    hlsInstance?: any,
    hlsGlobal?: any,
  ) => void;
  onDuration?: (duration: number) => void;
  onSeek?: (seconds: number) => void;
  onProgress?: (state: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => void;
}

type VideoPlayProps = ReactPlayerProps & {
  className?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
};

export const VideoPlayer = forwardRef<ReactPlayer, VideoPlayProps>(
  ({ className, containerRef, ...reactPlayerProps }, ref) => (
    <div className={cx("video-player", className)} ref={containerRef}>
      <ReactPlayer
        muted // For autoplay purposes
        {...reactPlayerProps}
        className="video-container"
        width={reactPlayerProps.width ?? "100%"}
        height={reactPlayerProps.height ?? "100%"}
        ref={ref}
      />
    </div>
  ),
);
