import { Icon } from "component";
import { blogDefaultProfileUrl } from "data/blog.info";
import dayjs from "dayjs";
import { BlogPost } from "interfaces/model/blog.model";
import { Link, RichText } from "prismic-reactjs";
import readingTime from "reading-time";
import "styles/views/BlogPage/BlogPageBlog.sass";

interface BlogPageBlogProps {
  blogPost: BlogPost;
}
export const BlogPageBlog = ({ blogPost }: BlogPageBlogProps) => {
  const {
    imageUrl,
    author,
    title,
    content,
    description,
    tagList,
    createDate,
  } = blogPost;

  return (
    <div className="blog-page-blog">
      <div className="inner-container">
        <div className="header">
          <img src={imageUrl} alt="blog preview" />

          <div className="title-container">
            <h2 className="title">{title}</h2>
          </div>

          <div className="author-container">
            <div className="author-info">
              <img
                src={
                  author.profilePictureUrl
                    ? Link.url(author.profilePictureUrl)
                    : blogDefaultProfileUrl
                }
                alt="author"
              />

              <div className="basic-info">
                <p className="name">{author.name}</p>
                <div className="time-info">
                  <p>{dayjs(createDate).format("MMM DD, YY")}</p>
                  <p className="dot">·</p>
                  <p>{readingTime(RichText.asText(content)).text}</p>
                </div>
              </div>
            </div>

            <div className="social-media-container">
              {author.socialMediaList.map(({ type, url }, key) => (
                <a
                  key={key}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={RichText.asText(url)}
                  className="social-media"
                >
                  <Icon icon={["fab", type]} />
                </a>
              ))}
            </div>
          </div>

          {tagList && tagList.length > 0 && (
            <ul className="tag-list">
              {tagList.map((tag, key) => (
                // TODO: Add onclick?
                <li key={key}>{tag}</li>
              ))}
            </ul>
          )}
        </div>

        <div className="main-content">
          <RichText render={description} />
          <RichText render={content} />
        </div>
      </div>
    </div>
  );
};
