import { deleteAccountInstructionImg } from "asset/common";
import "styles/page/DataDeletionInstructionPage.sass";

const FbDataDeletionInstructionPage = () => {
  return (
    <div className="data-deletion-instruction-page">
      <div className="data-deletion-instruction-page-inner">
        <img
          src={deleteAccountInstructionImg}
          alt="Delete account instruction"
          className="heading-image"
        ></img>
        <p>
          Stockwise does not store your personal data; it is used only for
          login. As per Facebook policy, we have to provide{" "}
          <strong>Data Deletion Instructions URL</strong>. If you want to delete
          your activities for the Stockwise, following these instructions:
        </p>
        <ol>
          <li>
            Go to Your Facebook Account's Setting & Privacy. Click{" "}
            <strong>Setting</strong>.
          </li>
          <li>
            Then, go to <strong>Apps and Websites</strong> and you will see all
            of your Apps activities.
          </li>
          <li>Select the option box for Stockwise.</li>
          <li>
            Click <strong>Remove</strong> button.
          </li>
        </ol>
        <p>
          If you wish to delete your account data on our platform, you can
          follow these instructions:
        </p>
        <ol>
          <li>Open Stockwise app and log in to your account.</li>
          <li>
            Open the drawer menu (located at the top left corner of the screen)
            and tap <strong>Account</strong> to go to the Account Screen.
          </li>
          <li>
            Scroll down to the bottom of the Account Screen, and then tap the{" "}
            <strong>Delete My Account</strong>.
          </li>
          <li>
            A confirmation dialog will pop up. Make sure you read all the
            warnings & instructions before proceeding to delete your account.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default FbDataDeletionInstructionPage;
