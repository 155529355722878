import { library } from "@fortawesome/fontawesome-svg-core";
import * as fab from "@fortawesome/free-brands-svg-icons";
import * as far from "@fortawesome/free-regular-svg-icons";
import * as fas from "@fortawesome/free-solid-svg-icons";

export const initializeIconList = () => {
  const iconList = [
    fas.faTimesCircle,
    fas.faBars,
    far.faCopyright,
    fas.faStar,
    fas.faCheck,
    fas.faInfoCircle,
    fas.faPlus,
    fas.faMinus,
    fas.faCircle,

    fas.faChevronDown,
    fas.faChevronRight,
    fas.faChevronLeft,

    fab.faFacebook,
    fab.faTwitter,
    fab.faInstagram,
    fab.faLinkedin,

    fas.faListUl,
    fas.faTags,
    fas.faUserSecret,
    far.faComments,
    fas.faComments,
    fas.faRocket,
    fas.faAtlas,
    fas.faHeart,
    far.faHeart,
    fas.faBookmark,
    far.faBookmark,
    fas.faBell,
    far.faBell,
  ];

  // Set up icon libraries
  library.add(...iconList);
};
