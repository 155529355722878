import Icon from "component/Icon";
import { PricingCard } from "component/Pricing";
import { pricingList } from "data/page/Pricing.data";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import "styles/page/Pricing.sass";

const Pricing = () => {
  const [showMonthlyPrice] = useState(true);

  const renderPricingList = () =>
    pricingList.map((ele) => (
      <PricingCard
        key={ele.title}
        content={ele.content}
        Illustration={ele.Illustration}
        title={ele.title}
        des={ele.des}
        price={showMonthlyPrice ? ele.monthlyPrice : ele.annualPrice}
        popular={ele.popular}
      />
    ));

  const renderPrice = (priceStr: string, showTooltip?: boolean) => (
    <td>
      <div>
        <p>{priceStr}</p>
        {showTooltip && (
          <p data-tip="Free for a Limited Time">
            <Icon icon={["fas", "info-circle"]} />
          </p>
        )}
      </div>
    </td>
  );

  return (
    <div className="pricing-container">
      <Helmet>
        <title>Stockwise Pricing</title>
        <meta name="description" content="For Traders By Traders" />

        <meta itemProp="name" content="Stockwise Pricing" />
        <meta itemProp="description" content="For Traders By Traders" />
      </Helmet>

      <div className="pricing">
        <div className="plan-container">
          <div className="plan-wrapper">
            <div className="title">
              <h1>Pricing</h1>
              {/* <p>Save 10% with annual plan</p> */}
              {/* <ButtonSwitch
                defaultActive={showMonthlyPrice}
                inactiveText="Annual"
                activeText="Monthly"
                onChange={(isActive) => {
                  setShowMonthlyPrice(isActive);
                }}
              /> */}
            </div>
            <div className="plan">{renderPricingList()}</div>
          </div>
        </div>
        <div className="compare-container">
          <div className="compare">
            <h1>Compare Plans</h1>
            <div className="title-mobile">
              <div>
                <p>Feature</p>
              </div>
              <div className="plans">
                <p>Free</p>
                <p>Basic</p>
                <p>Premium</p>
                <p>Elite</p>
              </div>
            </div>
            <div className="compare-table-container">
              <table className="compare-table">
                <tr>
                  <th>Feature</th>
                  <th>Free</th>
                  <th>Basic</th>
                  <th>Premium</th>
                  <th>Elite</th>
                </tr>
                <tr className="pricing-container">
                  <td />
                  {renderPrice("Free")}
                  {renderPrice("Free", true)}
                  {renderPrice("Free", true)}
                  {renderPrice("Free", true)}
                </tr>
                <tr>
                  <td>No ads</td>
                  <td></td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                </tr>
                <tr>
                  <td>Basic alerts</td>
                  <td>3</td>
                  <td>Unlimited</td>
                  <td>Unlimited</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>Chained alerts</td>
                  <td>1</td>
                  <td>3</td>
                  <td>Chained alerts</td>
                  <td>Chained alerts</td>
                </tr>
                <tr>
                  <td>Alert's condition</td>
                  <td>3</td>
                  <td>3</td>
                  <td>10</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Push Notification for Alert</td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                </tr>
                <tr>
                  <td>Email Notification for Alert</td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                </tr>
                <tr>
                  <td>SMS Notification for Alert</td>
                  <td></td>
                  <td></td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                </tr>
                <tr>
                  <td>Smart Alert</td>
                  <td></td>
                  <td></td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                </tr>
                <tr>
                  <td>Broker CSV Upload</td>
                  <td></td>
                  <td></td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                </tr>
                <tr>
                  <td>Brokerage Integration</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                </tr>
                <tr>
                  <td>Watch List Chart</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                </tr>
                <tr>
                  <td>Journal AI Integration</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Icon icon={["fas", "check"]} />
                  </td>
                </tr>
              </table>
            </div>
            <button>Sign up today</button>
          </div>
        </div>
      </div>

      <ReactTooltip />
    </div>
  );
};

export default Pricing;
