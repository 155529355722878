import Prismic from "@prismicio/client";
import { DefaultClient } from "@prismicio/client/types/client";
import {
  authorKeyMap,
  blogKeyMap,
  BLOG_PREVIEW_PER_PAGE,
  catergoryKeyMap,
  prismicUrl,
  socialMediaKeyMap,
} from "data/blog.info";
import { Action, action, thunk, Thunk, ThunkOn, thunkOn } from "easy-peasy";
import { BlogPostDto, BlogPostPreviewDto } from "interfaces/dtos/blog.dto";
import { BlogPost, BlogPreview } from "interfaces/model/blog.model";
import { RichText } from "prismic-reactjs";
import { toast } from "react-toastify";
import { StoreModel } from "stores/StoreFront";

type Category = { id: string; text: string };
export interface BlogStoreModel {
  isLoading: boolean;
  setIsLoading: Action<BlogStoreModel, boolean>;

  prismicClient: DefaultClient | null;
  setPrismicClient: Action<BlogStoreModel, DefaultClient>;

  curPage: number;
  changePage: Action<BlogStoreModel, number>;
  totalPage: number;
  setTotalPage: Action<BlogStoreModel, number>;

  searchTerm: string;
  setSearchTerm: Action<BlogStoreModel, string>;

  previewBlogList: BlogPreview[];
  setPreviewBlogList: Action<BlogStoreModel, BlogPreview[]>;

  categoryList: Category[];
  setCategoryList: Action<BlogStoreModel, Category[]>;

  tagList: string[];
  setTagList: Action<BlogStoreModel, string[]>;

  chosenTagList: string[];
  addOrRemoveChosenTag: Action<BlogStoreModel, string>;

  onCriteriaChange: ThunkOn<BlogStoreModel>;

  // TODO: Add fetch to get list of all categories and such here
  // TODO: Add request for pagination here
  initializeStore: Thunk<BlogStoreModel>;
  fetchPreviewBlogList: Thunk<
    BlogStoreModel,
    never,
    any,
    StoreModel,
    Promise<{ blogPreviewList: BlogPreview[]; totalPage: number }>
  >;
  fetchBlog: Thunk<
    BlogStoreModel,
    string,
    any,
    StoreModel,
    Promise<BlogPost | null>
  >;
}

export const blogStore: BlogStoreModel = {
  isLoading: false,
  setIsLoading: action((state, isLoading) => {
    state.isLoading = isLoading;
  }),

  prismicClient: null,
  setPrismicClient: action((state, client) => {
    state.prismicClient = client;
  }),

  curPage: 0,
  changePage: action((state, newPage) => {
    state.curPage = newPage;
  }),
  totalPage: 0,
  setTotalPage: action((state, totalPage) => {
    state.totalPage = totalPage;
  }),

  searchTerm: "",
  setSearchTerm: action((state, searchTerm) => {
    state.searchTerm = searchTerm;
  }),

  previewBlogList: [],
  setPreviewBlogList: action((state, blogList) => {
    state.previewBlogList = blogList;
  }),

  categoryList: [],
  setCategoryList: action((state, categoryList) => {
    state.categoryList = categoryList;
  }),

  tagList: [],
  setTagList: action((state, tagList) => {
    state.tagList = tagList;
  }),

  chosenTagList: [],
  addOrRemoveChosenTag: action((state, tag) => {
    const foundIndex = state.chosenTagList.findIndex(
      (curTag) => tag === curTag
    );

    if (foundIndex < 0) {
      state.chosenTagList.push(tag);
    } else {
      state.chosenTagList.splice(foundIndex, 1);
    }
  }),

  onCriteriaChange: thunkOn(
    (actions) => [
      actions.addOrRemoveChosenTag,
      actions.setSearchTerm,
      actions.changePage,
    ],
    async (actions, payload) => {
      const result = await actions.fetchPreviewBlogList();
      actions.setPreviewBlogList(result.blogPreviewList);
      actions.setTotalPage(result.totalPage);
    }
  ),

  initializeStore: thunk(async (actions) => {
    const prismicClient = Prismic.client(prismicUrl);
    actions.setPrismicClient(prismicClient);

    // --- Tag
    actions.setTagList((await prismicClient.getApi()).tags);

    // --- Category
    const categoryList = (
      await prismicClient.query([
        Prismic.Predicates.at("document.type", blogKeyMap.category),
      ])
    ).results.map((result) => ({
      id: result.id,
      text: RichText.asText(result.data.category),
    }));
    actions.setCategoryList(categoryList);

    // --- Data
    const result = await actions.fetchPreviewBlogList();
    actions.setPreviewBlogList(result.blogPreviewList);
    actions.setTotalPage(result.totalPage);
  }),

  fetchPreviewBlogList: thunk(async (actions, _2, { getState }) => {
    actions.setIsLoading(true);
    try {
      const prismicClient = getState().prismicClient;

      if (prismicClient) {
        const predicateList = [
          Prismic.Predicates.at("document.type", blogKeyMap.type),
          Prismic.Predicates.fulltext("document", getState().searchTerm),
        ];

        const tagList = getState().chosenTagList;
        if (tagList.length > 0) {
          predicateList.push(Prismic.Predicates.at("document.tags", tagList));
        }

        const mainCategory = "Stockwise";
        const chosenIndex = getState().categoryList.find(
          (entry) => entry.text === mainCategory
        )?.id;
        if (chosenIndex) {
          predicateList.push(
            Prismic.Predicates.at(
              `my.${blogKeyMap.type}.${blogKeyMap.category}`,
              chosenIndex
            )
          );
        } else {
          console.error(`Catergory ${mainCategory} not found`);
        }

        const response = await prismicClient.query(predicateList, {
          fetchLinks: [
            `${authorKeyMap.type}.${authorKeyMap.name}`,
            `${catergoryKeyMap.type}.${catergoryKeyMap.category}`,
          ],
          pageSize: BLOG_PREVIEW_PER_PAGE,
          page: getState().curPage + 1,
        });

        const dataList = response.results.map((result) => {
          const data = result.data;
          return BlogPostPreviewDto.toSchema({
            id: result[blogKeyMap.id],
            title: data[blogKeyMap.title],
            author: data[blogKeyMap.author],
            create_date: data[blogKeyMap.createDate],
            main_image: data[blogKeyMap.mainImage],
            category: data[blogKeyMap.category],
          });
        });

        return { blogPreviewList: dataList, totalPage: response.total_pages };
      }
    } catch (error) {
      const errStr = JSON.stringify(error, Object.getOwnPropertyNames(error));
      toast(`Failed to load blog post! ${errStr}`, {
        type: "error",
      });
      console.error(error);
    } finally {
      actions.setIsLoading(false);
    }
    return { blogPreviewList: [], totalPage: 0 };
  }),

  fetchBlog: thunk(async (actions, id, { getState }) => {
    actions.setIsLoading(true);

    try {
      const prismicClient = getState().prismicClient;
      if (prismicClient) {
        const response = await prismicClient.query(
          Prismic.predicates.at("document.id", id),
          {
            fetchLinks: [
              `${authorKeyMap.type}.${authorKeyMap.name}`,
              `${authorKeyMap.type}.${authorKeyMap.profilePicture}`,
              `${authorKeyMap.type}.${authorKeyMap.socialMediaList}`,
              `${socialMediaKeyMap.type}.${socialMediaKeyMap.mediaType}`,
              `${socialMediaKeyMap.type}.${socialMediaKeyMap.url}`,
              `${catergoryKeyMap.type}.${catergoryKeyMap.category}`,
            ],
          }
        );

        const result = response.results[0];
        if (result) {
          const data = result.data;
          return BlogPostDto.toSchema({
            id: result[blogKeyMap.id],
            title: data[blogKeyMap.title],
            author: data[blogKeyMap.author],
            description: data[blogKeyMap.description],
            content: data[blogKeyMap.content],
            create_date: data[blogKeyMap.createDate],
            main_image: data[blogKeyMap.mainImage],
            category: data[blogKeyMap.category],
            tag_list: result[blogKeyMap.tagList],
          });
        }

        return null;
      }
      return null;
    } catch (error) {
      const errStr = JSON.stringify(error, Object.getOwnPropertyNames(error));
      toast(`Failed to load blog post! ${errStr}`, {
        type: "error",
      });
      console.error(error);
      return null;
    } finally {
      actions.setIsLoading(false);
    }
  }),
};
