import { AppStoreImage, GooglePlayImage } from "asset/page";
import { MouseEventHandler, useEffect, useRef } from "react";
import "styles/component/DownloadButtons.sass";

export const DownloadButtons = () => {
  const playPromptRef = useRef<Event | null>();
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      playPromptRef.current = e;
    });
  }, []);

  const onPlayClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (playPromptRef.current) {
      event.preventDefault();

      // @ts-ignore
      playPromptRef.current.prompt();

      // Wait for the user to respond to the prompt
      // @ts-ignore
      playPromptRef.current.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        playPromptRef.current = null;
      });
    }
  };

  return (
    <div className="download-buttons">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://apps.apple.com/my/app/stockwise-by-apheleia/id1564378464"
        className="app-store-download-button"
      >
        <AppStoreImage className="download-button-img" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://play.google.com/store/apps/details?id=com.apheleia.stockwise_mobile"
        onClick={onPlayClick}
        className="google-play-download-button"
      >
        <GooglePlayImage className="download-button-img" />
      </a>
    </div>
  );
};
