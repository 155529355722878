import dayjs from "dayjs";
import { BlogPreview } from "interfaces/model/blog.model";
import { Link } from "react-router-dom";
import "styles/component/BlogPage/BlogPreviewCard.sass";
import { constructUrlFromTitleAndId } from "utils/url.util";
interface BlogPreviewCardProps {
  blogPreview: BlogPreview;
  basePath?: string;
  authorPath?: string;
}

export const BlogPreviewCard = ({
  blogPreview,
  basePath,
  authorPath,
}: BlogPreviewCardProps) => {
  const { title, id, author, imageUrl, createDate } = blogPreview;
  const toPath = `${basePath}/${constructUrlFromTitleAndId(title, id)}`;

  return (
    <div className="blog-preview-card">
      <Link className="preview-image" to={toPath}>
        <img src={imageUrl} alt="preview" />
      </Link>

      <div className="card-wrapper">
        <div className="meta-container">
          <h5>{dayjs(createDate).format("MMM DD, YYYY")}</h5>
        </div>

        <Link className="title" to={toPath}>
          {title}
        </Link>

        <Link className="author" to={`${authorPath}/${author.name}`}>
          {author.name}
        </Link>
      </div>
    </div>
  );
};
