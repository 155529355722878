import {
  AlertChainedSVG,
  alertIllustrationImgUrl,
  alertScreenImgUrl,
  AlertSingleSVG,
  BellBlueDuotoneSVG,
  BellSVG,
  ChartSVG,
  EditGoldenDuotoneSVG,
  heroScreen1ImgUrl,
  journalScreenImgUrl,
  NoteSVG,
} from "asset/page";
import { DownloadButtons } from "component/DownloadButtons";
import { FeatureDetail } from "component/Feature/FeatureDetail";
import { FeatureShowcase } from "component/Feature/FeatureShowcase";
import { FeatureSocialFeedSection } from "component/Feature/FeatureSocialFeedSection";
import { routePathMap } from "data/routeList";
import { Helmet } from "react-helmet";
import "styles/page/FeaturePage.sass";

const PREVIEW_IMAGE_URL =
  "https://stockwise-static.s3.us-west-2.amazonaws.com/preview-home-page.PNG";
export const FeaturePage = () => (
  <div className="feature-page">
    <Helmet>
      <title>Stockwise</title>
      <meta name="description" content="For Traders By Traders" />

      <meta itemProp="name" content="Stockwise" />
      <meta itemProp="description" content="For Traders By Traders" />
      <meta itemProp="image" content={PREVIEW_IMAGE_URL} />

      <meta property="og:url" content="https://stockwise.io/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Stockwise" />
      <meta property="og:description" content="For Traders By Traders" />
      <meta property="og:image" content={PREVIEW_IMAGE_URL} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="stockwise.io" />
      <meta property="twitter:url" content="https://twitter.com/apheleiatech" />
      <meta name="twitter:title" content="Stockwise" />
      <meta name="twitter:description" content="For Traders By Traders" />
      <meta name="twitter:image" content={PREVIEW_IMAGE_URL} />
    </Helmet>

    <FeatureShowcase />

    <FeatureDetail
      className="alert-section-detail"
      LeadingComponent={BellBlueDuotoneSVG}
      title="ALERT"
      description="Never miss the perfect opportunity to trade"
      learnMoreUrl={routePathMap.featureAlert}
      detailItemList={[
        {
          ItemLeadingComponent: AlertSingleSVG,
          itemTitle: "Single Alert",
          itemDescription:
            "Stockwise has a growing list of over 25+ technical indicators, from simple price targets to MACD.",
        },
        {
          ItemLeadingComponent: AlertChainedSVG,
          itemTitle: "Chained Alert",
          itemDescription:
            "Bring your trading to the next level with Chained alerts. Combine any number of technical indicators so that you can advance your trading strategies.",
        },
      ]}
      detailImgUrl={alertIllustrationImgUrl}
    />
    <FeatureDetail
      className="journal-section-detail"
      LeadingComponent={EditGoldenDuotoneSVG}
      title="JOURNAL"
      description="Create your strategy"
      learnMoreUrl={routePathMap.featureJournal}
      detailItemList={[
        {
          ItemLeadingComponent: NoteSVG,
          itemTitle: "Note",
          itemDescription:
            "Write down your thoughts and create your own trading strategy.",
        },
        {
          ItemLeadingComponent: ChartSVG,
          itemTitle: "Chart",
          itemDescription: "Visually look at your trading strategy in action.",
        },
        {
          ItemLeadingComponent: BellSVG,
          itemTitle: "Link Brokerage",
          itemDescription:
            "Automatically link your trades from your broker into your stockwise account.",
        },
      ]}
      isReverse={true}
      detailImgUrl={journalScreenImgUrl}
    />

    <FeatureSocialFeedSection />

    <FeatureBottom />
  </div>
);

const FeatureBottom = () => {
  return (
    <div className="feature-bottom">
      <div className="feature-bottom-inner">
        <div
          className="section-title"
          data-aos="fade-up"
          data-aos-delay="0"
          data-aos-duration="800"
        >
          Invest Smarter!
        </div>
        <div
          className="section-description"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="800"
        >
          Sign up today for free!
        </div>
        <div
          className="section-actions"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="800"
        >
          <DownloadButtons />
        </div>
        <div className="section-bottom-imgs">
          <img
            src={alertScreenImgUrl}
            alt="Mobile overview"
            className="img-left"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="800"
          />
          <img
            src={heroScreen1ImgUrl}
            alt="Mobile overview"
            className="img-right"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="800"
          />
          <img
            src={journalScreenImgUrl}
            alt="Mobile overview"
            className="img-center"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="400"
            data-aos-duration="800"
          />
        </div>
      </div>
    </div>
  );
};
