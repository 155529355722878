import { CustomPagination } from "component";
import { BlogPreviewCard } from "component/BlogPage";
import { blogPathMap } from "data/routeList";
import React, { useEffect } from "react";
import FadeIn from "react-fade-in";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useStoreActions, useStoreState } from "stores/StoreFront";
import "styles/views/BlogPage/BlogPageMain.sass";

export const BlogPageMain = () => {
  const blogList = useStoreState((state) => state.blogStore.previewBlogList);
  const totalPage = useStoreState((state) => state.blogStore.totalPage);
  const isLoading = useStoreState((state) => state.blogStore.isLoading);
  const { initializeStore, changePage } = useStoreActions(
    (actions) => actions.blogStore,
  );

  useEffect(() => {
    initializeStore();
  }, [initializeStore]);

  return (
    <div className="blog-page-main">
      <h2 className="blog-header">Blog</h2>

      <div className="blog-body">
        <div className="card-section">
          <div className="inner-container">
            {blogList.length > 0 ? (
              blogList.map((entry, index) => (
                <FadeIn
                  transitionDuration={400}
                  delay={index * 200}
                  key={index}
                >
                  <BlogPreviewCard
                    blogPreview={entry}
                    basePath={blogPathMap.post}
                    authorPath={blogPathMap.author}
                  />
                </FadeIn>
              ))
            ) : (
              <span className="no-result-text">
                No result! Please check your criteria
              </span>
            )}
          </div>

          {isLoading && (
            <FadeIn className="loading-overlay">
              <p>Loading</p>
              <div className="spinner-container">
                <PropagateLoader />
              </div>
            </FadeIn>
          )}

          {totalPage > 0 && (
            <CustomPagination totalPage={totalPage} onPageChange={changePage} />
          )}
        </div>
      </div>
    </div>
  );
};
