import loadingData from "asset/animation/40-loading.json";
import { LogoMobile } from "asset/component";
import { LottiePlayer } from "component/LottiePlayer";
import "styles/views/LoadingView.sass";

export const LoadingView = () => {
  return (
    <div className="loading-view">
      <img src={LogoMobile} alt="logo" />
      <LottiePlayer animationData={loadingData} height={100} width={100} />
    </div>
  );
};
