import { LogoMobile } from "asset/component";
import { Icon } from "component";
import { externalRoutePathMap, routePathMap } from "data/routeList";
import { Link } from "react-router-dom";
import "styles/component/Footer.sass";

interface FooterSectionData {
  title: string;
  items: {
    label: string;
    link: string;
    isInternal?: boolean;
  }[];
}

const footerSectionList: FooterSectionData[] = [
  {
    title: "COMPANY",
    items: [
      { label: "Stockwise Story", link: "#" },
      { label: "Our Team", link: "https://www.apheleia.me/" },
    ],
  },
  {
    title: "PRODUCT",
    items: [
      { label: "Pricing", link: routePathMap.pricing, isInternal: true },
      {
        label: "Stock Journal",
        link: routePathMap.featureJournal,
        isInternal: true,
      },
      {
        label: "Stock Alert",
        link: routePathMap.featureAlert,
        isInternal: true,
      },
    ],
  },
  {
    title: "SUPPORT",
    items: [
      { label: "Blog", link: routePathMap.blog, isInternal: true },
      { label: "FAQ", link: "#" },
      { label: "Contact Us", link: "https://www.apheleia.me" },
    ],
  },
];

export const Footer = () => {
  const renderSection = (section: FooterSectionData, index: number) => (
    <div key={"footer-section-" + section.title + index} className="section">
      <div className="section-title">{section.title}</div>
      {section.items.map((item) => (
        <div key={item.label + item.link} className="section-item">
          {item.isInternal ? (
            <Link to={item.link}>{item.label}</Link>
          ) : (
            <a href={item.link}>{item.label}</a>
          )}
        </div>
      ))}
    </div>
  );

  const renderSocialLink = (args: { link: string; iconName: string }) => (
    <a target="_blank" rel="noreferrer" href={args.link}>
      <Icon icon={["fab", args.iconName]} className="icon" />
    </a>
  );

  return (
    <>
      <div className="footer-info">
        <div className="footer-info-inner">
          <div className="logo-and-sections">
            <div className="logo-container">
              <img src={LogoMobile} className="logo" alt="logo" />
            </div>

            <div className="sections-container">
              {footerSectionList.map(renderSection)}

              <div className="section icons-container">
                <div className="section-title">Connect</div>

                {renderSocialLink({
                  iconName: "twitter",
                  link: "https://twitter.com/apheleiatech",
                })}
                {renderSocialLink({
                  iconName: "instagram",
                  link: "https://www.instagram.com/apheleiatech",
                })}
                {renderSocialLink({
                  iconName: "linkedin",
                  link: "https://www.linkedin.com/company/apheleiatech",
                })}
                {renderSocialLink({
                  iconName: "facebook",
                  link: "https://www.facebook.com/Apheleia-Tech-113485637182546",
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-bottom-inner">
          <div className="copyright-name">
            © Stockwise {new Date().getFullYear()}
          </div>
          <a target="_blank" rel="noreferrer" href={externalRoutePathMap.legal}>
            Terms
          </a>
          <a target="_blank" rel="noreferrer" href={externalRoutePathMap.legal}>
            Privacy Policy
          </a>
        </div>
      </div>
    </>
  );
};
