import Icon from "component/Icon";
import React from "react";
import ReactPaginate from "react-paginate";
import "styles/component/Pagination.sass";

interface PaginationProps {
  totalPage: number;
  onPageChange: (arg: number) => void;
}
export const CustomPagination = ({
  totalPage,
  onPageChange,
}: PaginationProps) => {
  return (
    <ReactPaginate
      previousLabel={<Icon icon={["fas", "chevron-left"]} />}
      nextLabel={<Icon icon={["fas", "chevron-right"]} />}
      breakLabel="..."
      pageCount={totalPage}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={({ selected }) => {
        onPageChange(selected);
      }}
      breakClassName="break-me"
      containerClassName="pagination-container"
      activeClassName="active"
    />
  );
};
