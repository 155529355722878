export const prismicUrl = "https://apheleia.prismic.io/api/v2";
export const blogDefaultProfileUrl =
  "https://images.prismic.io/apheleia/ed394afd-ef30-4e11-a5e2-98e1b5e7d4d7_default_profile.jpg?auto=compress,format";

export const blogKeyMap = {
  type: "blog_post",
  id: "id",
  title: "title",
  author: "author",
  description: "description",
  content: "content",
  createDate: "create_date",
  mainImage: "main_image",
  category: "category",
  tagList: "tags",
} as const;

export const authorKeyMap = {
  type: "author",
  name: "name",
  profilePicture: "profile_picture",
  socialMediaList: "social_media_list",
} as const;

export const socialMediaKeyMap = {
  type: "social_media",
  mediaType: "type",
  url: "url",
} as const;

export const catergoryKeyMap = {
  type: "category",
  category: "category",
};

export const BLOG_PREVIEW_PER_PAGE = 10;
