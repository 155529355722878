import { Transition } from "@headlessui/react";
import { Logo } from "asset/component";
import cx from "classnames";
import Icon from "component/Icon";
import {
  RouteInfo,
  routeList,
  RouteSectionDropdown,
  routeSectionDropdownList,
} from "data/routeList";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useDebounce, useHoverDirty } from "react-use";
import "styles/component/Header.sass";

const HEADER_TEXT_CN = "header-text";
const HEADER_TEXT__ACTIVE_CN = "header-text--active";
const HEADER_TEXT__BLUR_CN = "header-text--blur";

type SubmenuProp = {
  routeList: readonly RouteInfo[];
  left: number;
  isActive: boolean;
};

const Submenu = ({ routeList, left, isActive }: SubmenuProp) => {
  const { pathname } = useLocation();

  const renderRoute = ({ displayText, icon, iconSet, path }: RouteInfo) => (
    <>
      <Icon
        icon={
          iconSet
            ? pathname === path
              ? iconSet.active
              : iconSet.inactive
            : icon
        }
        className="fa-fw icon"
      />
      {displayText}
    </>
  );

  return (
    <Transition
      as="div"
      show={isActive}
      className="header-submenu"
      enterFrom="header-submenu--inactive"
      enterTo="header-submenu--active"
      entered="header-submenu--active"
      leaveFrom="header-submenu--active"
      leaveTo="header-submenu--inactive"
    >
      <ul className="inner-container" style={{ left }}>
        {(routeList ?? []).map((route) =>
          !route.isExternal && route.Component ? (
            <NavLink
              key={route.path}
              to={route.path}
              className={cx(HEADER_TEXT_CN)}
              activeClassName={HEADER_TEXT__ACTIVE_CN}
              exact={route.isExact}
            >
              {renderRoute(route)}
            </NavLink>
          ) : (
            <a
              key={route.path}
              href={route.path}
              target="_blank"
              rel="noreferrer"
              className={cx(HEADER_TEXT_CN)}
            >
              {renderRoute(route)}
            </a>
          ),
        )}
      </ul>
    </Transition>
  );
};

type ActivateSubmenu = (
  routeList: readonly RouteInfo[],
  isActive?: boolean,
) => void;

const HeaderItemWithSubmenu = ({
  displayText,
  routeList,
  activateSubmenu,
  isActive,
  isBlur,
  submenuLeft,
}: RouteSectionDropdown & {
  activateSubmenu: ActivateSubmenu;
  isActive: boolean;
  isBlur: boolean;
  submenuLeft: number;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(containerRef);

  useDebounce(() => activateSubmenu(routeList, isHovering), 100, [isHovering]);
  return (
    <div ref={containerRef} className="header-item-with-submenu">
      <div
        className={cx(HEADER_TEXT_CN, {
          [HEADER_TEXT__ACTIVE_CN]: isActive,
          [HEADER_TEXT__BLUR_CN]: isBlur,
        })}
      >
        <p>{displayText}</p>
        <Icon icon={["fas", "chevron-down"]} className="icon fa-fw" />
      </div>

      <Submenu routeList={routeList} left={submenuLeft} isActive={isActive} />
    </div>
  );
};

const Header = () => {
  // * Nav container
  const navContainerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const navContainer = navContainerRef.current;
    if (navContainer) {
      const childrenList = navContainer.children;
      const childElementList = Array.from(childrenList) as (
        | HTMLAnchorElement
        | HTMLButtonElement
      )[];

      childElementList.forEach((child) => {
        child.addEventListener("mouseenter", () => {
          childElementList.forEach((sibling) => {
            if (sibling !== child) {
              sibling.classList.add(HEADER_TEXT__BLUR_CN);
            }
          });
        });

        child.addEventListener("mouseleave", () => {
          childElementList.forEach((sibling) => {
            if (sibling !== child) {
              sibling.classList.remove(HEADER_TEXT__BLUR_CN);
            }
          });
        });
      });
    }
  }, []);

  // * Submenu
  const [submenuInfo, setSubmenuInfo] = useState<{
    routeList: readonly RouteInfo[];
    activeIndex: number;
  } | null>(null);

  const activateSubmenu: (index: number) => ActivateSubmenu =
    (index) => (routeList, isActive) => {
      setSubmenuInfo(isActive ? { routeList, activeIndex: index } : null);
    };

  const submenuLeft = useMemo(() => {
    const navContainer = navContainerRef.current;
    if (navContainer) {
      const childrenList = navContainer.children;
      const childElementList = Array.from(childrenList) as (
        | HTMLAnchorElement
        | HTMLButtonElement
      )[];

      return childElementList[0].getBoundingClientRect().left;
    }

    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navContainerRef.current]);

  return (
    <div className="header-container">
      <div className="inner-container">
        <div className="header">
          <div className="logo">
            <NavLink to="/" activeClassName="selected" exact>
              <Logo />
            </NavLink>
          </div>

          <nav className="content" ref={navContainerRef}>
            {routeSectionDropdownList.map((entry, index) => (
              <HeaderItemWithSubmenu
                key={entry.displayText}
                activateSubmenu={activateSubmenu(index)}
                isActive={submenuInfo?.activeIndex === index}
                isBlur={!!submenuInfo && submenuInfo.activeIndex !== index}
                submenuLeft={submenuLeft}
                {...entry}
              />
            ))}

            {routeList.map(
              (route) =>
                !route.isExternal &&
                !route.hidden &&
                route.Component && (
                  <NavLink
                    key={route.path}
                    to={route.path}
                    className={cx(HEADER_TEXT_CN, {
                      [HEADER_TEXT__BLUR_CN]: !!submenuInfo,
                    })}
                    activeClassName={HEADER_TEXT__ACTIVE_CN}
                    exact={route.isExact}
                  >
                    {route.displayText}
                  </NavLink>
                ),
            )}
          </nav>
        </div>

        {/* <div className="login">
          <a href={stockwiseLoginUrl}>Login</a>
          <a href={stockwiseRegisterUrl} className="get-started">
            Create Account
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
