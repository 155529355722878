import { Disclosure, Transition } from "@headlessui/react";
import { LogoMobile } from "asset/component";
import cx from "classnames";
import { DownloadButtons } from "component/DownloadButtons";
import {
  routeList,
  RouteSectionDropdown,
  routeSectionDropdownList,
} from "data/routeList";
import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "styles/component/Sidebar.sass";
import Icon from "./Icon";

const renderContentInner = (content: string, icon: [string, string]) => (
  <>
    <Icon icon={icon} className="fa-fw" />
    <p>{content}</p>
  </>
);

const ItemWithSubMenu = ({
  displayText,
  icon,
  routeList,
  onClick,
}: RouteSectionDropdown & { onClick: () => void }) => {
  const { pathname } = useLocation();

  return (
    <Disclosure as="li" className="item-with-sub-menu">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={cx("opener", { "opener--active": open })}
          >
            <div className="content">
              {renderContentInner(displayText, icon)}
            </div>
            <Icon icon={["fas", open ? "minus" : "plus"]} />
          </Disclosure.Button>

          <Transition
            as="ul"
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0 h-0"
            enterTo="transform scale-100 opacity-100 h-auto"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            {routeList.map(
              ({
                path,
                isExternal,
                isExact,
                icon,
                iconSet,
                displayText,
                Component,
              }) => (
                <li key={path}>
                  {!isExternal && Component ? (
                    <NavLink
                      to={path}
                      activeClassName="link-active"
                      exact={isExact}
                      onClick={onClick}
                    >
                      <Icon
                        icon={
                          iconSet
                            ? pathname === path
                              ? iconSet.active
                              : iconSet.inactive
                            : icon
                        }
                      />
                      {displayText}
                    </NavLink>
                  ) : (
                    <a href={path} target="_blank" rel="noreferrer">
                      <Icon icon={icon} />
                      {displayText}
                    </a>
                  )}
                </li>
              ),
            )}
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

interface MobileSidebarSliderProps {
  isActive: boolean;
  onCloseClick: () => void;
}
const MobileSidebarSlider = ({
  isActive,
  onCloseClick,
}: MobileSidebarSliderProps) => {
  const renderContent = () =>
    routeList.map((item, index) => (
      <li key={index}>
        {item.hidden ? null : !item.isExternal ? (
          <NavLink
            to={item.path}
            onClick={onCloseClick}
            className="link"
            activeClassName="link-active"
            exact
          >
            {renderContentInner(item.displayText, item.icon)}
          </NavLink>
        ) : (
          <a href={item.path} className="link" target="_blank" rel="noreferrer">
            {renderContentInner(item.displayText, item.icon)}
          </a>
        )}
      </li>
    ));

  return (
    <div
      className={cx("mobile-sidebar-slider", {
        "mobile-sidebar-slider-active": isActive,
      })}
    >
      <div className="inner-container">
        <div className="header">
          <Link className="logo" to="/" onClick={onCloseClick}>
            <img src={LogoMobile} alt="logo" />
          </Link>

          <button className="close-cta" onClick={onCloseClick}>
            <Icon icon={["fas", "times-circle"]} />
          </button>
        </div>

        <nav className="content-container">
          <ul className="content">
            {routeSectionDropdownList.map((item) => (
              <ItemWithSubMenu
                key={item.displayText}
                onClick={onCloseClick}
                {...item}
              />
            ))}
            {renderContent()}
          </ul>

          {/* <WebLink /> */}
          <DownloadButtons />
        </nav>
      </div>
    </div>
  );
};

export const Sidebar = () => {
  const [isActive, setIsActive] = useState(false);

  const onHamburgerClick = () => {
    setIsActive(true);
  };

  const closeSlider = () => {
    setIsActive(false);
  };

  return (
    <>
      <div className="sidebar-container">
        <div className="sidebar-wrapper">
          <button className="icon" onClick={onHamburgerClick}>
            <Icon icon={["fas", "bars"]} />
          </button>

          <div className="logo">
            <img src={LogoMobile} alt="logo" />
          </div>
        </div>

        <div className="sidebar"></div>
      </div>
      <MobileSidebarSlider isActive={isActive} onCloseClick={closeSlider} />
    </>
  );
};
export default Sidebar;
