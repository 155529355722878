import AOS from "aos";
import { Footer, Header, Sidebar } from "component";
import { routeList } from "data/routeList";
import { useGA4React } from "ga-4-react";
import { useScrollToTop } from "hooks";
import { Suspense, useEffect } from "react";
import { hotjar } from "react-hotjar";
import { Route, Switch } from "react-router-dom";
import "styles/main/App.sass";
import { initializeIconList } from "utils/iconInitializer";
import { LoadingView } from "views/LoadingView";

initializeIconList();

const hotjarId = 2352904;
const hotjarSnippetVersion = 6;
hotjar.initialize(hotjarId, hotjarSnippetVersion);

function App() {
  useGA4React("G-BCM4P73CCY");
  useScrollToTop();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Suspense fallback={LoadingView}>
      <div className="app-container">
        <Header />
        <Sidebar />
        <div className="app">
          <Switch>
            {routeList.map(
              (route) =>
                !route.isExternal &&
                route.Component && (
                  <Route
                    key={route.path}
                    component={route.Component}
                    path={route.path}
                    exact={route.isExact}
                  />
                ),
            )}
          </Switch>
        </div>

        <Footer />
      </div>
    </Suspense>
  );
}

export default App;
