import {
  alertCreateImgUrl,
  BellBlueDuotoneSVG,
  EditBlueDuotoneSVG,
  JournalLaptopImage,
  journalViewImgUrl,
  PhoneImage,
  SocialPeopleImage,
  SvgComponent,
} from "asset/page";
import { Carousel } from "component/Carousel";
import { DownloadButtons } from "component/DownloadButtons";
import { breakpointMap } from "data/breakpoint.data";
import { RoutePath, routePathMap } from "data/routeList";
import { Link } from "react-router-dom";
import { useMedia } from "react-use";
import "styles/component/Feature/FeatureShowcase.sass";

const carouselImageSrcList = [
  { src: journalViewImgUrl, alt: "Stockwise Journal" },
  { src: alertCreateImgUrl, alt: "Stockwise Alert" },
] as const;
export const FeatureShowcase = () => {
  const isMd = useMedia(breakpointMap.md);
  const isLg = useMedia(breakpointMap.lg);
  const renderImg = (
    { alt, src }: typeof carouselImageSrcList[number],
    index: number,
  ) => (
    <img
      src={src}
      alt={alt}
      key={src}
      {...(isLg
        ? {
            "data-aos": "fade-left",
            "data-aos-delay": `${100 * index}`,
            "data-aos-duration": "800",
            "data-offset": "-100",
            "data-aos-once": true,
          }
        : {})}
    />
  );

  return (
    <div className="feature-showcase">
      <div className="outer-container">
        <div className="showcase-content">
          <div
            className="content-header"
            data-aos="fade-right"
            data-aos-delay="0"
            data-aos-duration="800"
          >
            Unlock your trading capabilities
          </div>
          <div
            className="content-body"
            data-aos="fade-right"
            data-aos-delay="100"
            data-aos-duration="800"
          >
            Stay consistent and stay profitable with the best stock alarm and
            stock journal on the market.
          </div>
          <div
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="800"
          >
            <DownloadButtons />
          </div>
        </div>

        {!isMd ? (
          <Carousel dataList={carouselImageSrcList} renderItem={renderImg} />
        ) : (
          <div className="img-container">
            {carouselImageSrcList.map(renderImg)}
          </div>
        )}
      </div>

      <SectionHighlights />
    </div>
  );
};

const SectionHighlights = () => {
  const renderSectionHighlightItem = (args: {
    title: string;
    description: string;
    image: SvgComponent;
    url: RoutePath;
    TitleIcon: SvgComponent;
  }) => (
    <Link
      to={args.url}
      className="item"
      data-aos="fade-up"
      data-aos-delay="100"
      data-aos-duration="800"
    >
      <div className="item-image">
        <args.image height={170} />
      </div>
      <div className="item-icon-and-title">
        <args.TitleIcon />
        <span className="title">{args.title}</span>
      </div>
      <div className="item-description">{args.description}</div>
    </Link>
  );

  return (
    <div className="highlight-section">
      <div
        className="highlight-title"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        How can Stockwise help you trade better?
      </div>
      <div className="highlight-items">
        {renderSectionHighlightItem({
          title: "Alert",
          description: "Never miss the perfect opportunity to trade.",
          image: PhoneImage,
          url: routePathMap.featureAlert,
          TitleIcon: BellBlueDuotoneSVG,
        })}
        {renderSectionHighlightItem({
          title: "Journal",
          description: "Create your strategy and become a better trader.",
          image: JournalLaptopImage,
          url: routePathMap.featureJournal,
          TitleIcon: EditBlueDuotoneSVG,
        })}
        {renderSectionHighlightItem({
          title: "Social",
          description: "Share your thoughts and see what others are doing.",
          image: SocialPeopleImage,
          url: routePathMap.featureJournal,
          TitleIcon: EditBlueDuotoneSVG,
        })}
      </div>
    </div>
  );
};
