import { blogPathMap } from "data/routeList";
import { BlogPost } from "interfaces/model/blog.model";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { useStoreActions, useStoreState } from "stores/StoreFront";
import { getIdFromUrl } from "utils/url.util";
import { BlogPageBlog, BlogPageMain } from "views/BlogPage";

const BlogPage = () => {
  const matchPostUrl = useRouteMatch<{ postUrl: string }>(
    `${blogPathMap.post}/:postUrl`,
  );

  const [curBlog, setCurBlog] = useState<BlogPost | null>(null);

  const blogList = useStoreState((state) => state.blogStore.previewBlogList);
  const { fetchBlog } = useStoreActions((actions) => actions.blogStore);

  useEffect(() => {
    if (matchPostUrl && matchPostUrl.params.postUrl) {
      const newId = getIdFromUrl(matchPostUrl.params.postUrl);
      if (!curBlog || curBlog.id !== newId) {
        const handleGetNewBlog = async () => {
          const newBlog = await fetchBlog(newId);
          setCurBlog(newBlog);
        };
        handleGetNewBlog();
      }
    } else {
      setCurBlog(null);
    }
  }, [matchPostUrl, blogList, fetchBlog, curBlog]);

  return (
    <div className="blogPage">
      <Helmet>
        <title>Stockwise Blogs</title>
        <meta name="description" content="For Traders By Traders" />

        <meta itemProp="name" content="Stockwise Blogs" />
        <meta itemProp="description" content="For Traders By Traders" />
      </Helmet>

      {curBlog ? <BlogPageBlog blogPost={curBlog} /> : <BlogPageMain />}
    </div>
  );
};

export default BlogPage;
