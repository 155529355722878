import { BlogPage, Pricing } from "page";
import FbDataDeletionInstructionPage from "page/DataDeletionInstructionPage";
import { FeaturePage } from "page/FeaturePage";
import { lazy, LazyExoticComponent } from "react";

const FeatureJournalPage = lazy(
  () => import("page/feature/FeatureJournalPage")
);
const FeatureAlertPage = lazy(() => import("page/feature/FeatureAlertPage"));

export const routePathMap = {
  featureOverview: "/",
  featureJournal: "/journal",
  featureAlert: "/alert",

  pricing: "/pricing",
  blog: "/blog",
  dataDeletionInstruction: "/data-deletion-instruction",
} as const;
export type RoutePath = typeof routePathMap[keyof typeof routePathMap];

export const externalRoutePathMap = {
  legal: "https://www.apheleia.me/legal",
  toTheMoonPodcast: "http://tothemoonpod.com/",
} as const;
type ExternalRoutePath =
  typeof externalRoutePathMap[keyof typeof externalRoutePathMap];

export interface RouteInfo {
  displayText: string;
  path: RoutePath | ExternalRoutePath;
  Component?: (() => JSX.Element) | LazyExoticComponent<() => JSX.Element>;
  icon: [string, string];
  iconSet?: {
    inactive: [string, string];
    active: [string, string];
  };

  isExternal?: boolean;
  isExact?: boolean;
  hidden?: boolean;
}

const routeMap: Record<
  keyof typeof routePathMap,
  RouteInfo & { path: RoutePath }
> = {
  featureOverview: {
    displayText: "Stockwise",
    path: routePathMap.featureOverview,
    Component: FeaturePage,
    icon: ["fas", "heart"],
    iconSet: { inactive: ["far", "heart"], active: ["fas", "heart"] },
    isExact: true,
    hidden: true,
  },
  featureJournal: {
    displayText: "Journal",
    path: routePathMap.featureJournal,
    icon: ["fas", "bookmark"],
    Component: FeatureJournalPage,
    hidden: true,
    iconSet: { inactive: ["far", "bookmark"], active: ["fas", "bookmark"] },
  },
  featureAlert: {
    displayText: "Alert",
    path: routePathMap.featureAlert,
    icon: ["fas", "bell"],
    Component: FeatureAlertPage,
    hidden: true,
    iconSet: { inactive: ["far", "bell"], active: ["fas", "bell"] },
  },

  pricing: {
    displayText: "Pricing",
    path: routePathMap.pricing,
    Component: Pricing,
    icon: ["fas", "tags"],
  },
  blog: {
    displayText: "Blog",
    path: routePathMap.blog,
    Component: BlogPage,
    icon: ["fas", "comments"],
    iconSet: { inactive: ["far", "comments"], active: ["fas", "comments"] },
    hidden: true,
  },
  dataDeletionInstruction: {
    displayText: "Data Deletion Instruction",
    path: routePathMap.dataDeletionInstruction,
    Component: FbDataDeletionInstructionPage,
    icon: ["fas", "comments"],
    iconSet: { inactive: ["far", "comments"], active: ["fas", "comments"] },
    hidden: true,
  },
};

const externalRouteMap: Record<
  keyof typeof externalRoutePathMap,
  RouteInfo & { path: ExternalRoutePath }
> = {
  legal: {
    displayText: "Privacy Policy",
    path: externalRoutePathMap.legal,
    icon: ["fas", "user-secret"],
    isExternal: true,
  },
  toTheMoonPodcast: {
    displayText: "To the Moon Podcast",
    path: externalRoutePathMap.toTheMoonPodcast,
    icon: ["fas", "rocket"],
    isExternal: true,
  },
};

export const routeList: readonly RouteInfo[] = [
  routeMap.featureOverview,
  routeMap.featureJournal,
  routeMap.featureAlert,
  routeMap.blog,
  routeMap.pricing,
  routeMap.dataDeletionInstruction,
  externalRouteMap.legal,
];

export interface RouteSectionDropdown {
  displayText: string;
  tempPath: string;
  icon: [string, string];
  routeList: readonly RouteInfo[];
}
export const routeSectionDropdownList: RouteSectionDropdown[] = [
  {
    displayText: "Features",
    icon: ["fas", "list-ul"],
    tempPath: routePathMap.featureOverview,
    routeList: [routeMap.featureJournal, routeMap.featureAlert],
  },
  {
    displayText: "Resources",
    icon: ["fas", "atlas"],
    tempPath: routePathMap.blog,
    routeList: [routeMap.blog, externalRouteMap.toTheMoonPodcast],
  },
];

export const blogPathMap = {
  post: routePathMap.blog + "/post",
  category: routePathMap.blog + "/category",
  author: routePathMap.blog + "/author",
};
